import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -376px;
  right: -449px;
  ${media.md} {
    top: -432px;
  }
  ${media.sm} {
    top: -392px;
    right: -479px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 25px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: ${color.text.purple};
  }
  br {
    ${media.md} {
      display: none;
    }
  }
`;

export const ContentLeft = styled.div`
  padding-top: 98px;
  ${media.md} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 90px 0;
  }
  ${media.md} {
    padding: 0;
  }
  ${media.xs} {
    display: flex;
    justify-content: center;
  }
  img {
    position: absolute;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 310px;
      width: 550px;
    }
    ${media.md} {
      display: flex;
      justify-content: center;
      height: 310px;
      width: 420px;
    }
    ${media.sm} {
      height: 335px;
      width: 100%;
    }
    ${media.xs} {
      height: 283px;
      max-width: 320px;
    }
  }
  .illustration-content {
    top: 48%;
    width: 65%;
    left: 13%;
    ${media.sm} {
      left: auto;
    }
  }
  .illustration-content-logo {
    top: 60%;
    width: 15%;
    left: 37.8%;
    ${media.md} {
      top: 51%;
    }
    ${media.sm} {
      top: 58%;
      left: auto;
    }
    ${media.xs} {
      top: 45%;
    }
  }
  .illustration-action-one {
    top: -13%;
    width: 46%;
    left: 24%;
    ${media.sm} {
      left: auto;
    }
  }
  .illustration-action-two {
    top: 40%;
    left: -20.5%;
    width: 46.5%;
    ${media.sm} {
      left: -10.5%;
    }
    ${media.xs} {
      top: 32%;
      left: -20.5%;
    }
  }
  .illustration-action-three {
    top: 53%;
    left: 65.5%;
    width: 46%;
    ${media.xs} {
      top: 43%;
      left: 70.5%;
    }
  }
  .illustration-action-four {
    top: 76.5%;
    left: -0.5%;
    width: 94%;
    ${media.sm} {
      left: auto;
    }
    ${media.xs} {
      top: 66.5%;
    }
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 192px;
  ${media.lg} {
    padding-bottom: 22px;
    margin-bottom: 142px;
  }
  ${media.sm} {
    padding-bottom: 150px;
    margin-bottom: 32px;
  }
  ${media.xs} {
    padding-bottom: 20px;
  }
`;
