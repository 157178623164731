import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  Illustration,
} from './increases-sales-sales.styles';
import { Items } from './items';

export const SalesView = ({ title, description, items, illustration }) => (
  <Wrapper>
    <Inner>
      <Grid>
        <ContentLeft>
          <StyledTitle
            as="h2"
            size="xl"
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(title),
            }}
          />
          <Description
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(description),
            }}
          />
          <Items items={items} />
        </ContentLeft>
        <ContentRight>
          <Illustration fixed={illustration} />
        </ContentRight>
      </Grid>
    </Inner>
  </Wrapper>
);
