import * as React from 'react';

import { GridCell } from 'components/shared/grid';

import ItemIconSvgUrl3 from '../images/customer-exp.url.svg';
import ItemIconSvgUrl2 from '../images/customer-insights.url.svg';
import ItemIconSvgUrl1 from '../images/new-revenue.url.svg';
import ItemIconSvgUrl4 from '../images/savings.url.svg';

import {
  Grid,
  ItemWrapper,
  ItemContent,
  ItemImg,
  ImageStatic,
  ItemTitle,
  ItemDescription,
} from './increases-sales-sales-items.styles';

export const Items = ({ items }) => (
  <Grid
    columns={2}
    gap={0}
    media={{
      lg: { gap: 25 },
      md: { gap: 50 },
      sm: { columns: 1, gap: 0 },
    }}
  >
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl1} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle as="h3">{items[0].title}</ItemTitle>
          <ItemDescription>{items[0].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl2} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle as="h3">{items[1].title}</ItemTitle>
          <ItemDescription>{items[1].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl3} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle as="h3">{items[2].title}</ItemTitle>
          <ItemDescription>{items[2].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl4} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle as="h3">{items[3].title}</ItemTitle>
          <ItemDescription>{items[3].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
  </Grid>
);
