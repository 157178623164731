import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { SalesView } from './increases-sales-sales.view';

export const Sales = (props) => {
  const {
    illustrationSales: {
      childImageSharp: { fixed: illustrationSalesFixed },
    },
  } = useStaticQuery(graphql`
    query {
      illustrationSales: file(relativePath: { eq: "pages/increase-sales/sales/map-icp-3@2x.png" }) {
        childImageSharp {
          fixed(height: 743) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return <SalesView {...props} illustration={illustrationSalesFixed} />;
};
