import { motion } from 'framer-motion';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import IllustrtationBusinessSvgUrl from './images/illustration-business.url.svg';
import IllustrtationContentLogoSvgUrl from './images/illustration-content-logo.url.svg';
import IllustrtationContentRectUrl from './images/illustration-content-rect.url.svg';
import IllustrtationContentTitleSvgUrl from './images/illustration-content-title.url.svg';
import IllustrtationContentSvgUrl from './images/illustration-content.url.svg';
import TriangleSvgUrl from './images/solution-retailer.url.svg';
import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  BackgroundImageWrapper,
  TriangleImage,
} from './increases-sales-manage.styles';

export const Manage = ({ title, description }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 1,
  });

  const motionEase = [0.25, 0.1, 0.25, 1];

  const variantsContent = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
      transition: { delay: custom, duration: 0.5, ease: motionEase },
    }),
  };

  const variantsContentFade = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      transition: { delay: custom, duration: 0.5, ease: motionEase },
    }),
  };

  const variantsPosition = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      y: custom,
    }),
  };
  return (
    <Wrapper id="corporate-sales">
      <Inner>
        <Grid
          columns={2}
          gap={0}
          media={{
            sm: { columns: 1 },
          }}
        >
          <GridCell>
            <ContentLeft ref={animationContainer}>
              <motion.div
                className="illustration-wrapper"
                initial="hidden"
                animate={animationContainerView && 'visible'}
              >
                <motion.img
                  src={IllustrtationContentSvgUrl}
                  className="illustration-content"
                  variants={variantsPosition}
                  custom={-70}
                  transition={{ delay: 0, duration: 0.7, ease: motionEase }}
                />
                <motion.img
                  src={IllustrtationContentLogoSvgUrl}
                  className="illustration-content-logo"
                  variants={variantsContentFade}
                  custom={0.6}
                />
                <motion.img
                  src={IllustrtationContentTitleSvgUrl}
                  className="illustration-content-title"
                  variants={variantsContentFade}
                  custom={0.8}
                />
                <motion.img
                  src={IllustrtationContentRectUrl}
                  className="illustration-content-rect"
                  variants={variantsContent}
                  custom={0.9}
                />

                <motion.img
                  src={IllustrtationBusinessSvgUrl}
                  className="illustration-content-business"
                  variants={variantsPosition}
                  custom={-50}
                  transition={{ delay: 1.5, duration: 0.5, ease: motionEase }}
                />
              </motion.div>
            </ContentLeft>
          </GridCell>
          <GridCell>
            <ContentRight>
              <StyledTitle
                as="h2"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(title),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(description),
                }}
              />
            </ContentRight>
          </GridCell>
        </Grid>
      </Inner>
      <BackgroundImageWrapper>
        <TriangleImage src={TriangleSvgUrl} />
      </BackgroundImageWrapper>
    </Wrapper>
  );
};
