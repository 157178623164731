import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const LogoWrapper = styled.div`
  margin-right: 41px;
  ${media.sm} {
    margin-right: 0;
  }
  &:first-child {
    margin-right: 60px;
    ${media.xs} {
      margin-right: 8px;
    }
    .gatsby-image-wrapper {
      ${media.xs} {
        width: 30px !important;
      }
    }
  }
  &:nth-child(2) {
    margin-right: 64px;
    ${media.sm} {
      margin-right: 0;
    }
    .gatsby-image-wrapper {
      margin-top: 12px;
      width: 105px !important;
      ${media.sm} {
        width: 100px !important;
      }
      ${media.xs} {
        width: 50px !important;
      }
    }
  }
  &:nth-child(3) {
    margin-right: 48px;
    ${media.sm} {
      margin-right: 0;
    }
    .gatsby-image-wrapper {
      width: 81px !important;
      margin-top: 6px;
      ${media.xs} {
        width: 65px !important;
      }
    }
  }
  &:nth-child(4) {
    .gatsby-image-wrapper {
      margin-top: 11px;
      width: 70px !important;
      ${media.xs} {
        margin-top: 4px;
        width: 62px !important;
      }
    }
  }
  &:last-child {
    margin-right: 29px;
    ${media.lg} {
      margin-right: 0;
    }
    .gatsby-image-wrapper {
      height: 70px !important;
      ${media.xs} {
        width: 45px !important;
      }
    }
  }
  .gatsby-image-wrapper {
    height: 50px !important;
    ${media.xs} {
      width: 70px !important;
    }
    img {
      object-fit: contain !important;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  ${media.lg} {
    margin-left: 10px;
  }
  ${media.md} {
    margin-left: 0;
  }
  ${media.sm} {
    width: 100%;
    justify-content: space-between;
    margin-top: 9px;
  }
`;
