import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -624px;
  right: -376px;
  ${media.md} {
    top: -142px;
    right: -479px;
  }
  ${media.sm} {
    top: -439px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 20px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: ${color.text.purple};
  }
  br {
    ${media.sm} {
      display: none;
    }
  }
`;

export const ContentLeft = styled.div`
  padding-top: 130px;
  ${media.sm} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${media.lg} {
    padding: 80px 0;
  }

  ${media.sm} {
    padding: 0;
  }

  ${media.xs} {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;

    ${media.lg} {
      height: 370px;
      width: 530px;
    }

    ${media.md} {
      display: flex;
      justify-content: center;
      height: 340px;
      width: 430px;
    }

    ${media.sm} {
      height: 300px;
      width: 100%;
    }

    ${media.xs} {
      height: 200px;
      max-width: 320px;
    }
  }

  img {
    position: absolute;
  }

  .illustration-content {
    top: 31%;
    left: -5.5%;
    width: 101%;

    ${media.md} {
      top: 37%;
      width: 100%;
    }

    ${media.sm} {
      top: 30%;
      left: auto;
    }

    ${media.xs} {
      top: 46%;
    }
  }

  .illustration-content-title {
    top: 34%;
    left: 8.5%;
    width: 26.5%;

    ${media.sm} {
      left: 13.5%;
    }
  }

  .illustration-content-logo {
    top: 53.5%;
    left: 12.5%;
    width: 23.5%;

    ${media.sm} {
      top: 57.5%;
      left: 17.5%;
    }
  }

  .illustration-content-rect {
    top: 44%;
    left: 46.2%;
    width: 32%;

    ${media.sm} {
      left: 50.2%;
    }
  }

  .illustration-content-btn {
    top: 74%;
    left: 46%;
    width: 32%;

    ${media.sm} {
      top: 87%;
      left: 50.2%;
    }
  }

  .illustration-content-action {
    top: 91.5%;
    left: 5.5%;
    width: 80%;

    ${media.sm} {
      top: 110.5%;
      left: auto;
    }
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 270px;
  ${media.lg} {
    padding-bottom: 0;
    margin-bottom: 150px;
  }
  ${media.sm} {
    padding-bottom: 200px;
    margin-bottom: 20px;
    padding-top: 60px;
  }
  ${media.xs} {
    padding-bottom: 100px;
  }
`;
