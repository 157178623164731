import { motion } from 'framer-motion';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import IllustrtationActionFourSvgUrl from './images/illustration-action-four.url.svg';
import IllustrtationActionOneSvgUrl from './images/illustration-action-one.url.svg';
import IllustrtationActionThreeSvgUrl from './images/illustration-action-three.url.svg';
import IllustrtationActionTwoSvgUrl from './images/illustration-action-two.url.svg';
import IllustrtationContentLogoSvgUrl from './images/illustration-content-logo.url.svg';
import IllustrtationContentSvgUrl from './images/illustration-content.url.svg';
import TriangleSvgUrl from './images/solutions-triangle-retailers.url.svg';
import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  BackgroundImageWrapper,
  TriangleImage,
} from './increases-sales-automate.styles';

export const Automate = ({ title, description }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 1,
  });

  const motionEase = [0.25, 0.1, 0.25, 1];
  const duration = 0.5;

  const transitionAction = {
    delay: 1,
    duration,
    ease: motionEase,
  };

  const variantsContentFade = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      transition: { delay: custom, duration, ease: motionEase },
    }),
  };

  const variantsPositionY = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      y: custom,
    }),
  };

  const variantsPositionX = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      x: custom,
    }),
  };

  return (
    <Wrapper id="party-sales">
      <Inner>
        <Grid
          columns={2}
          gap={0}
          media={{
            sm: { columns: 1 },
          }}
        >
          <GridCell>
            <ContentLeft>
              <StyledTitle
                as="h2"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(title),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(description),
                }}
              />
            </ContentLeft>
          </GridCell>
          <GridCell>
            <ContentRight ref={animationContainer}>
              <motion.div
                className="illustration-wrapper"
                initial="hidden"
                animate={animationContainerView && 'visible'}
              >
                <motion.img
                  src={IllustrtationActionThreeSvgUrl}
                  className="illustration-action-three"
                  variants={variantsPositionX}
                  custom={-50}
                  transition={transitionAction}
                />
                <motion.img
                  src={IllustrtationContentSvgUrl}
                  className="illustration-content"
                  variants={variantsPositionY}
                  custom={-70}
                  transition={{ delay: 0, duration: 0.7, ease: motionEase }}
                />

                <motion.img
                  src={IllustrtationContentLogoSvgUrl}
                  className="illustration-content-logo"
                  variants={variantsContentFade}
                  custom={1.5}
                />
                <motion.img
                  src={IllustrtationActionOneSvgUrl}
                  className="illustration-action-one"
                  variants={variantsPositionY}
                  custom={50}
                  transition={transitionAction}
                />
                <motion.img
                  src={IllustrtationActionTwoSvgUrl}
                  className="illustration-action-two"
                  variants={variantsPositionX}
                  custom={50}
                  transition={transitionAction}
                />

                <motion.img
                  src={IllustrtationActionFourSvgUrl}
                  className="illustration-action-four"
                  variants={variantsPositionY}
                  custom={-50}
                  transition={transitionAction}
                />
              </motion.div>
            </ContentRight>
          </GridCell>
        </Grid>
      </Inner>
      <BackgroundImageWrapper>
        <TriangleImage src={TriangleSvgUrl} />
      </BackgroundImageWrapper>
    </Wrapper>
  );
};
