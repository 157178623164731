import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const ItemWrapper = styled.div`
  margin-bottom: 81px;
  ${media.md} {
    margin-bottom: 0;
  }
  ${media.sm} {
    text-align: center;
    margin-bottom: 53px;
  }
`;

export const ItemContent = styled.div``;

export const ItemImg = styled.div`
  margin-bottom: 19px;
  svg {
    ${media.sm} {
      width: 70px;
    }
  }
`;

export const ImageStatic = styled.img``;

export const ItemTitle = styled(Title)`
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 9px;
  ${media.sm} {
    font-size: 20px;
  }
`;

export const ItemDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  white-space: pre-wrap;
  margin: 0;
  ${media.md} {
    white-space: initial;
  }
`;

export const Grid = styled(SharedGrid)`
  padding-right: 59px;
  ${media.sm} {
    padding-right: 0;
  }
`;
