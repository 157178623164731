import styled, { css } from 'styled-components';

import { color, fontSize, media, transition } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Wrapper = styled.div`
  position: relative;
  margin-top: 40px;
  z-index: 1;
`;

export const Inner = styled(Container)`
  @media (min-width: 1305px) {
    max-width: 1245px;
  }
`;

export const ItemButton = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: 1.44;
  color: ${color.text.purple};
  opacity: 0;
  transition: ${transition.base} all;
`;

export const GridItem = styled(GridCell)`
  ${(p) => css`
    ${p.borderRight &&
    css`
      box-shadow: inset -1px 0 0 0 #d9dfe4;
      ${media.md} {
        box-shadow: inset 0 0 1px 0 #d9dfe4;
      }
    `}
  `}
`;

export const Grid = styled(SharedGrid)`
  background-color: ${color.text.tertiary};
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  ${media.md} {
    box-shadow: 14px 46px 70px -35px rgba(50, 50, 93, 0.27), 2px 2px 22px 0 rgba(26, 26, 67, 0.01);
  }
`;

export const ItemWrapper = styled.a`
  display: block;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: ${transition.base} all;
  padding: 34px 25px 50px 35px;
  ${media.lg} {
    padding: 34px 20px 35px 20px;
  }
  ${media.md} {
    display: flex;
    padding: 34px 25px 35px 35px;
  }
  ${media.sm} {
    padding: 25px 25px 10px 30px;
  }
  &:hover {
    box-shadow: inset 0 -4px 0 0 ${color.text.purple}, inset -1px 0 0 0 #d9dfe4;
    ${ItemButton} {
      opacity: 1;
    }
  }
`;

export const ItemImg = styled.div`
  margin-bottom: 10px;
  ${media.md} {
    margin-bottom: 0;
    margin-right: 40px;
  }
  ${media.sm} {
    margin-right: 20px;
  }
  svg {
    ${media.sm} {
      width: 40px;
      height: auto;
    }
  }
`;

export const ImageStatic = styled.img``;

export const ItemContent = styled.div``;

export const ItemTitle = styled(Title)`
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 4px;
  ${media.sm} {
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const ItemDescription = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.44;
  color: #838791;
  margin-top: 0;
  ${media.sm} {
    font-size: 14px;
    line-height: normal;
  }
`;
