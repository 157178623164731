import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -8px;
  left: -201px;
  ${media.sm} {
    top: 202px;
    left: -313px;
    height: 575px;
  }
  ${media.xs} {
    height: 395px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.lg} {
    white-space: initial;
  }
  ${media.md} {
    white-space: pre-wrap;
  }
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 20px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 5px;
  }
  strong {
    font-weight: normal;
    color: ${color.text.purple};
  }
  br {
    ${media.sm} {
      display: none;
    }
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.xs} {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 420px;
      width: 530px;
    }
    ${media.md} {
      display: flex;
      justify-content: center;
      height: 209px;
      width: 330px;
    }
    ${media.sm} {
      height: 290px;
      width: 100%;
    }
    ${media.xs} {
      height: 153px;
      max-width: 320px;
    }
  }
  img {
    position: absolute;
  }
  .illustration-content {
    top: 31%;
    left: -9%;
    width: 101%;
    ${media.md} {
      top: 41%;
    }
    ${media.sm} {
      top: 31%;
      width: 100%;
      left: auto;
    }
    ${media.xs} {
      top: 50%;
    }
  }
  .illustration-content-title {
    top: 33.7%;
    left: 28.5%;
    width: 24%;
    ${media.sm} {
      left: 38.5%;
    }
  }
  .illustration-content-logo {
    top: 33.5%;
    left: 7.5%;
    width: 8.5%;
    ${media.sm} {
      left: 15.5%;
    }
  }
  .illustration-content-rect {
    top: 44%;
    left: 28.2%;
    width: 49%;
    ${media.sm} {
      left: 38.5%;
    }
  }
  .illustration-content-business {
    top: 90%;
    left: 9.5%;
    width: 70%;
    ${media.sm} {
      top: 113%;
      left: 19.5%;
    }
    ${media.xs} {
      top: 133%;
    }
  }
`;

export const ContentRight = styled.div`
  padding-top: 123px;
  padding-left: 82px;
  ${media.md} {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    ${media.sm} {
      flex-direction: column-reverse;
    }
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 205px;
  margin-bottom: 192px;
  ${media.md} {
    padding-bottom: 25px;
    margin-bottom: 122px;
  }
  ${media.sm} {
    padding-bottom: 300px;
    margin-bottom: 30px;
  }
  ${media.xs} {
    padding-bottom: 150px;
  }
`;
