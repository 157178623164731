import * as React from 'react';

import CapabilitieSvgUrl3 from '../icons/direct-consumer.url.svg';
import CapabilitieSvgUrl2 from '../icons/direct-corp.url.svg';
import CapabilitieSvgUrl4 from '../icons/internal-sales.url.svg';
import CapabilitieSvgUrl1 from '../icons/third-party.url.svg';

import {
  Wrapper,
  Inner,
  Grid,
  GridItem,
  ItemWrapper,
  ItemImg,
  ImageStatic,
  ItemContent,
  ItemTitle,
  ItemDescription,
  ItemButton,
} from './increases-sales-intro-capabilities-block.styles';

export const CapabilitiesBlock = ({ className, items }) => (
  <Wrapper className={className}>
    <Inner>
      <Grid
        columns={4}
        gap={0}
        media={{
          md: { columns: 1 },
        }}
      >
        <GridItem borderRight>
          <ItemWrapper href={`#${items[0].button_link}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl1} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[0].title}</ItemTitle>
              <ItemDescription>{items[0].description}</ItemDescription>
              <ItemButton>{items[0].button_text}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper href={`#${items[1].button_link}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl2} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[1].title}</ItemTitle>
              <ItemDescription>{items[1].description}</ItemDescription>
              <ItemButton>{items[1].button_text}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper href={`#${items[2].button_link}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl3} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[2].title}</ItemTitle>
              <ItemDescription>{items[2].description}</ItemDescription>
              <ItemButton>{items[2].button_text}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem>
          <ItemWrapper href={`#${items[3].button_link}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl4} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[3].title}</ItemTitle>
              <ItemDescription>{items[3].description}</ItemDescription>
              <ItemButton>{items[3].button_text}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
      </Grid>
    </Inner>
  </Wrapper>
);
