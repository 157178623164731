import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.lg} {
    white-space: initial;
  }
  ${media.md} {
    white-space: pre-wrap;
  }
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 32px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 5px;
  }
  strong {
    font-weight: normal;
    color: ${color.text.purple};
  }
  br {
    ${media.sm} {
      display: none;
    }
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.xs} {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 310px;
      width: 530px;
    }
    ${media.md} {
      display: flex;
      justify-content: center;
      height: 250px;
      width: 420px;
      left: -40px;
    }
    ${media.sm} {
      height: 320px;
      width: 100%;
      left: auto;
    }
    ${media.xs} {
      height: 203px;
      max-width: 320px;
    }
  }
  img {
    position: absolute;
  }
  .illustration-content {
    top: 34%;
    left: -3.5%;
    width: 70%;
    ${media.md} {
      top: 40%;
    }
    ${media.sm} {
      top: 35%;
    }
    ${media.xs} {
      top: 51%;
    }
  }
  .illustration-content-title {
    top: 38.5%;
    left: 24.7%;
    width: 10%;
  }
  .illustration-content-desc {
    top: 44.5%;
    left: 14.3%;
    width: 32.5%;
  }
  .illustration-content-logo {
    top: 71.5%;
    left: 13%;
    width: 24%;
  }
  .illustration-content-rect {
    top: 110%;
    left: 11.5%;
    width: 38%;
  }
  .illustration-content-business {
    top: 108%;
    left: 31%;
    width: 70%;
  }
`;

export const ContentRight = styled.div`
  padding-top: 123px;
  padding-left: 52px;
  ${media.md} {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    ${media.sm} {
      flex-direction: column-reverse;
    }
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 205px;
  margin-bottom: 316px;
  ${media.lg} {
    padding-bottom: 25px;
    margin-bottom: 246px;
  }
  ${media.md} {
    padding-bottom: 25px;
    margin-bottom: 122px;
  }
  ${media.sm} {
    padding-bottom: 250px;
    margin-bottom: 20px;
  }
  ${media.xs} {
    padding-bottom: 100px;
  }
`;
