import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { SolutionsBanner } from 'components/blocks/solutions-banner';
import { Trusted } from 'components/blocks/trusted';
import { Automate } from 'components/pages/increases-sales/automate';
import { Consumers } from 'components/pages/increases-sales/consumers';
import { Deliver } from 'components/pages/increases-sales/deliver';
import { Intro } from 'components/pages/increases-sales/intro';
import { Manage } from 'components/pages/increases-sales/manage';
import { Sales } from 'components/pages/increases-sales/sales';

import { buildPageMetadata } from '../utils/data-builders';
import { extractItems } from '../utils/extract-items';

const IncreasesSales = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  const capabilityItems = extractItems(acf, 'section1Item');
  const trustedItems = extractItems(acf, 'section7Item');
  const featuresItems = extractItems(acf, 'section6Item');

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Intro
        labelText={acf.section1TextAboveTitle}
        title={acf.section1Title}
        buttonText={acf.section1ButtonText}
        buttonLink={acf.section1ButtonLink}
        bottomText={acf.section1BottomText}
        logos={acf.section1Logos}
        capabilities={capabilityItems}
      />

      <Automate title={acf.section2Title} description={acf.section2Description} />
      <Manage title={acf.section3Title} description={acf.section3Description} />
      <SolutionsBanner
        title={acf.section3BannerTitle}
        description={acf.section3BannerDescription}
        buttonText={acf.section3BannerButtonText}
        buttonLink={acf.section3BannerButtonLink}
      />
      <Consumers title={acf.section4Title} description={acf.section4Description} />
      <Deliver title={acf.section5Title} description={acf.section5Description} />
      <Sales
        title={acf.section6Title}
        description={acf.section6Description}
        items={featuresItems}
      />

      <Trusted
        theme="purple"
        title={acf.section7Title}
        description={acf.section7Description}
        image={acf.section7Image}
        imageLogo={acf.section7ImageLogo}
        buttonText={false}
        buttonLink={false}
        items={trustedItems}
        bottomTitle={acf.section7BottomTitle}
        bottomDescription={acf.section7BottomDescription}
        bottomButtonText={acf.section7BottomButtonText}
        bottomButtonLink={acf.section7BottomButtonLink}
      />
      <Footer theme="light" />
    </Layout>
  );
};

export default IncreasesSales;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section1TextAboveTitle
        section1Title
        section1Description
        section1ButtonText
        section1ButtonLink
        section1BottomText
        section1Logos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section1Item1Title
        section1Item1Description
        section1Item1ButtonText
        section1Item1ButtonLink
        section1Item2Title
        section1Item2Description
        section1Item2ButtonText
        section1Item2ButtonLink
        section1Item3Title
        section1Item3Description
        section1Item3ButtonText
        section1Item3ButtonLink
        section1Item4Title
        section1Item4Description
        section1Item4ButtonText
        section1Item4ButtonLink
        section2Title
        section2Description
        section2BottomTitle
        section2BottomDescription
        section2ButtonText
        section2ButtonLink
        section3Title
        section3Description
        section3BannerTitle
        section3BannerDescription
        section3BannerButtonText
        section3BannerButtonLink
        section4Title
        section4Description
        section5Title
        section5Description
        section6Title
        section6Description
        section6Item1Title
        section6Item1Description
        section6Item2Title
        section6Item2Description
        section6Item3Title
        section6Item3Description
        section6Item4Title
        section6Item4Description
        section7Title
        section7Description
        section7Image {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7ImageLogo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 223) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7ButtonText
        section7ButtonLink
        section7Item1Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item1Title
        section7Item1BottomText
        section7Item2Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item2Title
        section7Item2BottomText
        section7Item3Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 120) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item3Title
        section7Item3BottomText
        section7Item4Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item4Title
        section7Item4BottomText
        section7BottomTitle
        section7BottomDescription
        section7BottomButtonText
        section7BottomButtonLink
      }
    }
  }
`;
